@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  min-height: 100%;
  height: 100vh;
}

.troub-background {
  background: url(assets/images/background.jpg);
  min-height: 100%;
}

@font-face {
  font-family: 'Linux Libertine';
  src: url('assets/LinLibertine_aS.ttf');
}

.troubaheader,
.troubatext {
  font-family: 'Linux Libertine', serif;
}

.deck-viewer-card {
  filter: drop-shadow(0 0 1em rgba(69, 57, 18, 1));
  cursor: pointer;
  -webkit-transition: 0.2s -webkit-filter linear;
  -moz-transition: 0.2s -moz-filter linear;
  -moz-transition: 0.2s filter linear;
  -ms-transition: 0.2s -ms-filter linear;
  -o-transition: 0.2s -o-filter linear;
  transition: 0.2s filter linear, 1s -webkit-filter linear;
}

.deck-viewer-card-hover {
  filter: drop-shadow(0 0 0.5em rgba(217, 170, 104, 0));
  -webkit-transition: 0.2s -webkit-filter linear;
  -moz-transition: 0.2s -moz-filter linear;
  -moz-transition: 0.2s filter linear;
  -ms-transition: 0.2s -ms-filter linear;
  -o-transition: 0.2s -o-filter linear;
  transition: 0.2s filter linear, 1s -webkit-filter linear;
}

.deck-viewer-card-hover:hover {
  filter: drop-shadow(0 0 0.5em rgba(216, 203, 185, 0.95));
  -webkit-transition: 0.2s -webkit-filter linear;
  -moz-transition: 0.2s -moz-filter linear;
  -moz-transition: 0.2s filter linear;
  -ms-transition: 0.2s -ms-filter linear;
  -o-transition: 0.2s -o-filter linear;
  transition: 0.2s filter linear, 1s -webkit-filter linear;
}

.active-card-play-icon {
  opacity: 0.5;
  transition: 0.2s opacity linear, 1s -webkit-opacity linear;
}

.active-card-play-icon:hover {
  opacity: 1;
  transition: 0.2s opacity linear, 1s -webkit-opacity linear;
}

.troubadeck-card {
  border-radius: 5px;
  max-width: 100%;
}

.troub-background-dark {
  filter: drop-shadow(0 0 2em rgb(26, 21, 7));
  background: url('/public/troubadeck-card-back-bg-web.jpg');
  background-repeat: repeat;
  background-size: 20%;
  margin: 2rem 5% 6rem 5%;
  border-radius: 15px;
}

progress {
  color: #0063a6;
  line-height: 1.5em;
  text-indent: .5em;
  width: 15em;
  height: .2em;
  border: 1px solid #0063a6;
  background: #fff;
}